window.onload = () => {
  console.log("vof ui/nav.js from app/javascript...");

  // Main Nav

  let mainNav = document.querySelector(".main-nav")
  let hamburger = mainNav.querySelector(".hamburger")
  let navContainer = mainNav.querySelector(".nav-items-container")

  hamburger.onclick = () => {
    if(hamburger.classList.contains("is-active")) {
      hamburger.classList.remove("is-active")
      navContainer.classList.remove("open")
    } else {
      hamburger.classList.add("is-active")
      navContainer.classList.add("open")
    }
  }
}
